export const seo = {
  url: 'kontakt',
  title: {
    pl: 'Kontakt z firmą transportową. Eksperci od transportu, spedycji, logistyki.',
    en: 'Contact with the transport company. Experts in transport, forwarding and logistics',
  },
  desc: {
    pl: 'Skontaktuj się z ekspertami w dziedzinie logistyki. Ponad 30 oddziałów na obszarze całej Polski odpowiada za transport w całej Europie.',
    en: 'Get in touch with the logistics experts. More than 20 branches throughout Poland are responsible for transport throughout Europe.',
  },
  keywords: ['omida', 'logistics', 'transport'],
}

export const intro = {
  title: {
    pl: 'Kontakt',
    en: 'Contact',
  },
  desc: {
    pl: `
    <span>
      <strong>OMIDA LOGISTICS Sp. z o.o.</strong></br>
      AL. GRUNWALDZKA 472C<br/>
      80-309 GDAŃSK<br/>
      <br/>
      tel. +48 58 741 88 14</br>
      fax. +48 58 741 88 14<br/>
      <br/>
      NIP: 584 273 95 82 <br/>
      REGON: 360603722<br/>
      KRS: 0000539585<br/>
    </span>
    `,
    en: `
    <span>
      <strong>OMIDA LOGISTICS Sp. z o.o.</strong></br>
      AL. GRUNWALDZKA 472C<br/>
      80-309 GDAŃSK<br/>
      <br/>
      tel. +48 58 741 88 14</br>
      fax. +48 58 741 88 14<br/>
      <br/>
      NIP: 584 273 95 82 <br/>
      REGON: 360603722<br/>
      KRS: 0000539585<br/>
    </span>
    `,
  },
}

export const main = {
  title: {
    pl: 'Kontakt z firmą transportową',
    en: 'Contact with the transport company',
  },
  texts: [
    {
      pl: 'Nawiąż rozmowę z ekspertami w dziedzinie transportu, spedycji oraz logistyki. Znajdź odpowiedni dział lub oddział spedycyjny. Skorzystaj z szybkiego zapytania, wyceń transport, nawiąż współpracę lub dołącz do sieci przewodników - wyświetl formularze poprzez przycisk po prawej stronie ekranu.',
      en: 'Begin a conversation with experts in the field of transport, forwarding and logistics. Find the right shipping department or branch. Use a quick inquiry, quote a transport, establish cooperation or join a network of carriers - display the forms by clicking the button on the right side of the screen.',
    },
  ],
}
