import React from 'react'

const LocationOmida = (props) => (
  <svg
    css={{
      position: 'absolute',
      top: '0%',
      left: '50%',
      width: 64,
      height: 64,
      transform: 'translate(-50%, -100%)',
    }}
    {...props}
    xmlns='http://www.w3.org/2000/svg'
    xmlnsXlink='http://www.w3.org/1999/xlink'
    width='60'
    height='78'
    viewBox='0 0 60 78'>
    <defs>
      <filter
        id='Icon_material-location-on'
        x='0'
        y='0'
        width='60'
        height='78'
        filterUnits='userSpaceOnUse'>
        <feOffset dy='3' input='SourceAlpha' />
        <feGaussianBlur stdDeviation='3' result='blur' />
        <feFlood floodOpacity='0.161' />
        <feComposite operator='in' in2='blur' />
        <feComposite in='SourceGraphic' />
      </filter>
    </defs>
    <g id='Group_2' data-name='Group 2' transform='translate(-5182 -4624)'>
      <g
        transform='matrix(1, 0, 0, 1, 5182, 4624)'
        filter='url(#Icon_material-location-on)'>
        <path
          id='Icon_material-location-on-2'
          data-name='Icon material-location-on'
          d='M28.5,3a20.985,20.985,0,0,0-21,21c0,15.75,21,39,21,39s21-23.25,21-39A20.985,20.985,0,0,0,28.5,3Z'
          transform='translate(1.5 3)'
          fill='#fff'
        />
      </g>
      <path
        id='OmidaFavicon'
        d='M66.848,232.319h14a6.934,6.934,0,0,1,2.62.512,71.57,71.57,0,0,0-23.621,9.861v-3.373a7.02,7.02,0,0,1,7-7Zm18.459,1.616c-12.848,5.413-21.448,12.3-25.459,19.3v.082a6.949,6.949,0,0,0,1.008,3.607c4.46-7.977,13.341-16.339,24.979-22.505a6.932,6.932,0,0,0-.528-.485Zm1.747,2.153C76.726,244.3,70.707,252.816,69,260.319H80.848a7.019,7.019,0,0,0,7-7v-14a6.941,6.941,0,0,0-.795-3.231Z'
        transform='translate(5138.153 4405.681)'
        fill='#6bb53b'
        fillRule='evenodd'
      />
      {/* <path
        id='Polygon_1'
        data-name='Polygon 1'
        d='M9,0l9,11H0Z'
        transform='translate(5221 4690) rotate(180)'
        fill='#6bb53b'
      /> */}
    </g>
  </svg>
)

export default LocationOmida
